import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import CodeMirror from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import { EditorView } from '@codemirror/view';

function App() {
    const [backendData, setBackendData] = useState({ codes: [] });
	const [code, setCode] = useState("");
	const editorRefs = useRef([]);
  	const [isShow, setShow] = useState(false);
	const [loading, setLoading] = useState(false);
	const handleClose = () => setShow(false);
    const toast = useRef(null);

    const accept = () => {
        wipeData();
    }

    const confirmWipe = () => {
        confirmDialog({
            message: 'Are you sure you want to wipe all codes?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            className: 'p-ripple-disabled',
            acceptClassName: 'p-button-sm ',
            accept
        });
    };


    const items = [
        {
            label: 'Add',
            icon: 'pi pi-pencil',
            command: () => {
                setShow(true);
            }
        },
        {
            label: 'Wipe Codes',
            icon: 'pi pi-trash',
            command: () => {
                confirmWipe();
            }
        }
    ];

	const handleCopy = (index) => {
	  	if (editorRefs.current[index]) {
			const content = editorRefs.current[index].view.state.doc.toString();

			navigator.clipboard.writeText(content).then(() => {
                toast.current.show({severity:'success', summary: 'Success', detail:'Code copied to clipboard.', life: 3000});
			}).catch(err => {
                toast.current.show({severity:'error', summary: 'Error', detail:'Failed to copy content.', life: 3000});
			});

	  	}
	};

    const fetchCode = async () => {
        try {
            const response = await fetch('/api/answer/list');
            const data = await response.json();
            setBackendData({ codes: data });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const wipeData = async () => {
        try {
            const response = await fetch('/api/answer/wipe');
            const data = await response.json();
            console.log(data);
            toast.current.show({severity:'success', summary: 'Success', detail: data.message, life: 3000});
            fetchCode();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchCode();
    }, []);

	const handleCodeChange = (value) => {
		setCode(value);
	};

    const handleAddCode = async () => {
		setLoading(true);
        try {
            const response = await fetch("/api/answer/add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ code })
            });

            if (response.ok) {
                const jsonResponse = await response.json();
                toast.current.show({severity:'success', summary: 'Success', detail:jsonResponse.message, life: 3000});
                fetchCode();
                handleClose();
            } else {
                toast.current.show({severity:'error', summary: 'Error', detail:'Failed to add code.', life: 3000});
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
			setLoading(false);
		}
    };

    const footerContent = (
        <div>
            <Button label="Close" icon="pi pi-times" size="small" onClick={() => setShow(false)} className="p-button-text" />
            <Button label={loading ? 'Loading...' : 'Submit'} icon="pi pi-pencil" size="small" onClick={handleAddCode} />
        </div>
    );

    return (
        <div className="container mb-5">
            <div className="grid justify-content-center mx-auto">
                <div className="col-12 sm:col-8 mt-3">
                    <Dialog header="Add New Code" className="col-12 sm:col-8 mt-3" visible={isShow} draggable={false} onHide={() => {if (!isShow) return; setShow(false); }} footer={footerContent}>
                        <CodeMirror height="300px" theme={vscodeDark} extensions={[python({ jsx: true })]} onChange={handleCodeChange} />
                    </Dialog>
                    <ConfirmDialog draggable={false} />
                    <Card>
                        <Toast ref={toast} position="top-center" />
                        {(backendData.codes.length === 0) ? (
                            <Message className="w-full" text="No data..." />
                        ) : (
                            backendData.codes.map((user, i) => (
                                <div key={i} style={{ marginBottom: '1rem' }}>
                                    <CodeMirror
                                        ref={(el) => (editorRefs.current[i] = el)}
                                        value={user.Text}
                                        height="200px"
                                        theme={vscodeDark}
                                        extensions={[
                                            python({ jsx: true }),
                                            EditorView.editable.of(false)
                                        ]}
                                    />
                                    <Button label="Copy Code" className="mt-2" icon="pi pi-clipboard" size="small" onClick={() => handleCopy(i)} />
                                    {i < backendData.codes.length - 1 && <Divider />}
                                </div>
                            ))
                        )}
                    </Card>
                </div>
                <Tooltip target=".speeddial-bottom-right .p-speeddial-action" position="left" />
                <SpeedDial model={items} direction="up" transitionDelay={80} showIcon="pi pi-bars" hideIcon="pi pi-times" className="speeddial-bottom-right right-10 bottom-10" />
            </div>
        </div>
    );
}

export default App;